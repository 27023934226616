import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import { mainStore, plugnotasStore, plugnotasConfiguracaoStore, } from '@/utils/store-accessor';
import PeriodSelectorComponent from '@/components/PeriodSelectorComponent.vue';
import PlugnotasBoasVindasComponent from '@/components/PlugnotasBoasVindasComponent.vue';
let VisaoGeral = class VisaoGeral extends Vue {
    constructor() {
        super(...arguments);
        this.produtoHeaders = [
            { text: 'Produto', value: 'produto' },
            { text: 'Ativo', value: 'ativa' },
            {
                text: 'Emissão automática após baixa',
                value: 'gerar_automaticamente_apos_baixa',
            },
        ];
        this.produtoItems = [];
        this.emissaoHeaders = [
            { text: 'Produto', value: 'tipo_nota' },
            { text: 'Quantidade', value: 'quantidade_notas' },
            { text: 'Valor R$', value: 'total_valor_notas' },
        ];
        this.emissaoItems = [];
        this.selectPeriodDate = null;
        this.search = '';
        this.loading = true;
        this.isLoadingResumoEmissoes = false;
        this.plugnotasConfiguracao = null;
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    async onConfirmBoasVindas(plugnotasConfiguracao = null) {
        this.plugnotasConfiguracao = plugnotasConfiguracao;
    }
    async mounted() {
        this.loading = true;
        this.plugnotasConfiguracao =
            await plugnotasConfiguracaoStore.getPlugnotasConfiguracaoMe();
        this.produtoItems = await plugnotasStore.getPlugnotasProdutosStatus();
        this.loading = false;
        this.isLoadingResumoEmissoes = true;
        this.emissaoItems = await plugnotasStore.plugnotasResumoEmissaoNotas();
        this.isLoadingResumoEmissoes = false;
    }
};
VisaoGeral = __decorate([
    Component({
        components: {
            PeriodSelectorComponent,
            PlugnotasBoasVindasComponent,
        },
    })
], VisaoGeral);
export default VisaoGeral;
